<template>
  <UAlert
    v-if="!isOnline"
    icon="i-heroicons-signal-slash"
    color="yellow"
    title="Your device seems to be offline"
    variant="soft"
    :ui="{ rounded: 'rounded-none', padding: 'py-2' }"
  />
</template>

<script setup lang="ts">
const { isOnline } = useNetwork()
</script>

